import wrapWithProvider from "./wrap-with-provider";
import theme from "@instructure/canvas-theme";
import { purple, green } from "./src/shared/colors";

export const wrapRootElement = wrapWithProvider;

export const onClientEntry = () =>
  theme.use({
    overrides: {
      colors: {
        brand: purple,
        backgroundBrand: purple,
        backgroundSuccess: green,
        shamrock: green
      },
      "ic-brand-button--primary-bgd": purple
    }
  });
