import { createStore as reduxCreateStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import axios from "axios";
import learningModules from "./learningModules";
import { persistStore, persistReducer } from "redux-persist";
import localForage from "localforage";

const initialState = {
  modules: learningModules.modules,
  currentModule: 0,
  currentModuleStep: 0
};

const persistConfig = {
  key: "root",
  storage: localForage,
  blacklist: ["currentModule", "currentModuleStep"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [logger, thunk.withExtraArgument({ api: axios })];

const createStore = () => {
  const store = reduxCreateStore(
    persistedReducer,
    initialState,
    applyMiddleware(...middleware)
  );

  const persistor = persistStore(store);

  return { store, persistor };
};
export default createStore;
