export default {
  modules: [
    {
      id: 1,
      title: "Normalcy",
      completed: false,
      steps: [
        {
          id: 1,
          title: "Real Life Context",
          link: "/normalcy",
          completed: null
        },
        {
          id: 2,
          title: "What is Normalcy?",
          link: "/normalcy/what-is-normalcy",
          completed: null
        },
        {
          id: 3,
          title: "Normalcy Law",
          link: "/normalcy/normalcy-law",
          completed: null
        },
        {
          id: 4,
          title: "Normal Childhood Activities",
          link: "/normalcy/normal-childhood-activities",
          completed: null
        },
        {
          id: 5,
          title: "Reasonable & Prudent Parenting Standards",
          link: "/normalcy/reasonable-prudent-parenting-standards",
          completed: null
        },
        {
          id: 6,
          title: "High Risk Activites",
          link: "/normalcy/high-risk",
          completed: null
        },
        {
          id: 7,
          title: "Summary",
          link: "/normalcy/summary",
          completed: null
        }
      ]
    },
    {
      id: 2,
      title: "Travel",
      completed: false,
      steps: [
        {
          id: 1,
          title: "Introduction",
          link: "/travel",
          completed: null
        },
        {
          id: 2,
          title: "Rights",
          link: "/travel/rights",
          completed: null
        },
        {
          id: 3,
          title: "Process",
          link: "/travel/process",
          completed: null
        },
        {
          id: 4,
          title: "Court",
          link: "/travel/court",
          completed: null
        },
        {
          id: 5,
          title: "Application",
          link: "/travel/application",
          completed: null
        },
        {
          id: 6,
          title: "High Risk Activites",
          link: "/travel/summary",
          completed: null
        }
      ]
    },
    {
      id: 3,
      title: "Rights & Responsibilities",
      completed: false,
      steps: [],
      videoOnly: true
    },
    {
      id: 4,
      title: "Respite, Babysitting, & Daycare",
      completed: false,
      steps: [],
      videoOnly: true
    },
    {
      id: 5,
      title: "Property Damage, Emergency Situations, & Safe-to-sleep",
      completed: false,
      steps: [],
      videoOnly: true
    },
    {
      id: 6,
      title: "Reimbursements",
      completed: false,
      steps: [],
      videoOnly: true
    },
    {
      id: 7,
      title: "Advocacy, Schools, & Court",
      completed: false,
      steps: [],
      videoOnly: true
    },
    {
      id: 8,
      title: "Foster Family Supports",
      completed: false,
      steps: [],
      videoOnly: true
    },
    {
      id: 9,
      title: "Assessment, Placement, & Conflict Resolution",
      completed: false,
      steps: [],
      videoOnly: true
    }
  ]
};
