import { combineReducers } from "redux";

const modules = (state = [], action) => {
  switch (action.type) {
    case "COMPLETE_MODULE": {
      const newState = state.slice();
      newState.find(x => x.id === action.payload).completed = true;
      return newState;
    }
    case "SAVE_LOCAL_STATE_TO_GLOBAL": {
      const newState = state.slice();
      const module = newState.find(x => x.id === action.payload.moduleId);
      const step = module.steps.find(x => x.id === action.payload.stepId);
      step.localState = action.payload.localState;
      return newState;
    }
    default:
      return state;
  }
};

const currentModule = (state = 0, action) => {
  switch (action.type) {
    case "SET_CURRENT_MODULE":
      return action.payload;
    default:
      return state;
  }
};

const currentModuleStep = (state = 0, action) => {
  switch (action.type) {
    case "SET_CURRENT_MODULE":
      if (state === 0) {
        return 1;
      }
      return state;
    case "SET_CURRENT_STEP":
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  modules,
  currentModule,
  currentModuleStep
});
